import Glide from '@glidejs/glide';

if (document.getElementById('glide-endorsements')) {
  let glideEndorsements = new Glide('#glide-endorsements', {
    type: 'carousel',
    perView: 1,
    focusAt: 'center'
  });
  glideEndorsements.mount();
}

if (document.querySelector('#glide-videos')) {
  let glideVideos = new Glide('#glide-videos', {
    type: 'carousel',
    perView: 1,
    focusAt: 'center'
  });
  glideVideos.mount();

  function resetFrame() {
    const activeSlideIframe = document.querySelector(`#glide-videos .glide__slide--active iframe`);
    if (activeSlideIframe) {
      activeSlideIframe.src = activeSlideIframe.src;
    }
  }

  // reset videos when swiped so that we don't have multiple videos playing at once
  glideVideos.on('run.before', resetFrame);

}

if (document.querySelector('.glide-stories')) {
  let glideStories = new Glide('.glide-stories', {
    type: 'carousel',
    perView: 1,
    focusAt: 'center'
  });
  glideStories.mount();
}